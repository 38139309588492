const storeOptions = {
    persist: {
        paths: undefined,
        storage: persistedState.localStorage,
    },
};

export default defineStore(
    'projects',
    () => {
        const projects: Ref<TProject[]> = ref<TProject[]>([]);
        const showDeletedProjects = ref(false);
        const areProjectLoading = ref(false);

        async function fetchProjects() {
            try {
                areProjectLoading.value = true;

                const data = await $fetch<TProject[]>('/api/projects', {
                    baseURL: useRuntimeConfig().public.apiBase,
                    onResponseError: async (ctx) => {
                        if (ctx.response.status === 401) {
                            await navigateTo('/login');
                        }
                    },
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });

                projects.value = data || [];

                // projects.value.forEach((p) => {
                //     if (!['running', 'degraded'].includes(p.status || '')) {
                //         const s = useProjectTopologyStore(getActivePinia(), p);
                //         s.$dispose();
                //     }
                // });
            } catch {
                projects.value = [];
            } finally {
                areProjectLoading.value = false;
            }

            return projects;
        }
        /**
         * Create Project using GRPC
         */
        async function createProject(project: MaybeRef<TProject>) {
            await $fetch('/api/projects', {
                method: 'POST',
                body: {
                    project: unref(project),
                },
                mode: 'same-origin',
                watch: false,
                baseURL: useRuntimeConfig().public.apiBase,
                onResponseError: async (ctx) => {
                    if (ctx.response.status === 401) {
                        await navigateTo('/login');
                    }
                },
            });

            await fetchProjects();
        }

        async function updateProject(project: TProject) {
            await $fetch(`/api/projects/${project.id}`, {
                method: 'POST',
                body: {
                    project,
                },
                mode: 'same-origin',
                watch: false,
                baseURL: useRuntimeConfig().public.apiBase,
                onResponseError: async (ctx) => {
                    if (ctx.response.status === 401) {
                        await navigateTo('/login');
                    }
                },
            });

            await fetchProjects();
        }

        async function deleteProject(project: TProject) {
            await $fetch('/api/projects/' + project.id, {
                method: 'DELETE',
                baseURL: useRuntimeConfig().public.apiBase,
                onResponseError: async (ctx) => {
                    if (ctx.response.status === 401) {
                        await navigateTo('/login');
                    }
                },
            });

            await fetchProjects();
        }

        /**
         * Upgrade project Zenoh version
         *
         * @param project
         * @param versionId
         */
        async function upgrade(project: TProject, versionId: string) {
            const { error } = await useFetch(
                `/api/projects/${project.id}/upgrade`,
                {
                    method: 'POST',
                    baseURL: useRuntimeConfig().public.apiBase,
                    body: {
                        target: {
                            projectId: project.id,
                            versionId,
                        },
                    },
                    onResponseError: async (ctx) => {
                        if (ctx.response.status === 401) {
                            await navigateTo('/login');
                        }
                    },
                }
            );

            if (error.value) {
                throw new Error('Cannot upgrade project ' + error.value);
            }

            await fetchProjects();
        }

        function toggleDeletedProjects() {
            showDeletedProjects.value = !showDeletedProjects.value;
        }

        return {
            createProject,
            updateProject,
            upgrade,
            deleteProject,
            fetchProjects,
            toggleDeletedProjects,
            projects,
            showDeletedProjects,
            areProjectLoading,
        };
    },
    storeOptions
);
